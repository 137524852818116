<template>
  <div class="login">
    <div class="login__form">
      <LoginForm v-if="step === 1" @recovery="recovery($event)" @savePassword="savePassword($event)" @recoverAccount="recoveryAccount($event)"/>
      <PasswordRecovery v-if="step === 2" @back="step = 1" :email="email"/>
      <SavePassword v-if="step === 3" :oldPassword="oldPassword"/>
      <RecoverAccount v-if="step === 4" @back="step = 1" :password="password"/>
    </div>
    <UiSelectLanguages
        v-if="step === 1"
        :isTop="true"
        :options="languages"
        v-model="language"
        :label="$t('language')"
        class="login__languages"
        @input="changeLanguage"
    />
    <p class="login__delete" @click="$router.push('delete-account')">{{ $t('delete-account') }}</p>
  </div>
</template>

<script>
import LoginForm from '@/components/template/login/LoginForm'
import PasswordRecovery from "@/components/template/login/PasswordRecovery";
import SavePassword from "@/components/template/login/SavePassword.vue";
import RecoverAccount from "@/components/template/login/recover-account/RecoverAccount.vue";
import {mapMutations} from "vuex";
import UiSelectLanguages from "@/components/ui/UiSelectLanguages.vue";
import {getCookie, setCookie} from "@/utils/cookie";

export default {
  name: "Login",
  components: {
    UiSelectLanguages,
    RecoverAccount,
    SavePassword,
    LoginForm,
    PasswordRecovery
  },
  data() {
    return {
      step: 1,
      oldPassword: '',
      password: '',
      email: '',
      language: { name: this.$t('russian'), value: 'ru' },
      languages: [
        { name: this.$t('russian'), value: 'ru' },
        { name: this.$t('english'), value: 'en' }
      ]
    }
  },
  methods: {
    ...mapMutations([
      'setAuth',
    ]),
    savePassword (oldPassword) {
      this.oldPassword = oldPassword;
      this.step = 3;
    },

    recoveryAccount (password) {
      this.password = password;
      this.step = 4;
    },

    recovery (email) {
      this.step = 2
      this.email = email
    },

    changeLanguage () {
      this.$i18n.locale = this.language.value
      setCookie('locale', this.language.value)
      location.reload();
    }
  },
  mounted() {
    this.setAuth(false)
  },

  created() {
    this.languages.forEach(el => {
      if(el.value === getCookie('locale')) {
        this.language = el
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.login {
  display: flex;
  justify-content: center;
  background: #F5F5F5;
  height: 100%;
  min-height: 100vh;
  padding: 0 20px;

  @media (max-width: 850px) {
    padding-bottom: 100px;
  }

  &__form {
    margin: 150px 0 30px 0;
    padding: 30px 34px 40px 34px;
    background: #FFFFFF;
    box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
    border-radius: 20px;
    max-width: 434px;
    width: 100%;
    height: fit-content;

    @media(max-width: 768px) {
      margin: 120px 0 30px 0;
      padding: 30px 12px 40px 12px;
    }
  }

  &__delete {
    position: fixed !important;
    bottom: 50px;
    right: 90px;
    cursor: pointer;

    @media (max-width: 768px) {
      font-size: 12px;
      line-height: 11px;
    }

    @media (max-width: 1440px) {
      right: 50px;
    }

    @media (max-width: 850px) {
      position: absolute !important;
      right: 43%;
    }

    @media (max-width: 500px) {
      right: 37%;
      bottom: 40px;
    }
  }
}
::v-deep input {
  font-family: "Gotham Pro Regular";
}

::v-deep .select-languages {
  position: fixed !important;
  bottom: 50px;
  left: 90px;

  @media (max-width: 1440px) {
    left: 50px;
  }

  @media (max-width: 850px) {
    position: absolute !important;
    left: 43%;
  }

  @media (max-width: 500px) {
    left: 37%;
  }
}
</style>
